@import 'variables';

.app-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-button {
  font-weight: bold;

  &.mat-raised-button {
    border-radius: 1px;

    &.mat-primary {
      background-color: $primary;
    }
  }
}

mat-sidenav:not(.mat-drawer-opened) {
  //transform: translate3d(0, 0, 0) !important;
  transform: none !important;
  visibility: visible !important;
  width: 64px !important;
  overflow: hidden;

  .navigation-item-label {
    display: none !important;
  }
}

mat-sidenav-container:not(.mat-drawer-opened) {
  .mat-drawer-content {
    margin-left: 60px;
  }
}

.sidenav-container {
  background-color: #f0f0f7;
}

.mat-drawer {
  background-color: $primary;
  //transform: translate3d(0, 0, 0) !important;
  transform: none !important;
  width: 250px;

  .mat-list-base {
    .mat-list-item {
      color: $white;
      border-left-style: solid;
      border-left-width: 10px;
      border-left-color: #fff;
      height: 70px;

      &.active,
      &.child-active {
        border-left-color: #fff;
        // background-color: $shadow;
      }

      .mat-icon {
        color: $secondary;
        margin-right: 10px;
      }
    }
  }
}
//toolbar
.toolbar {
  background-color: #a3a0fb !important;
  &.mat-toolbar {
    padding: 0px !important;
    background-color: $white;
  }

  .header-left {
    background-color: #a3a0fb;
    display: flex;
    align-items: center;
    height: 100%;
    width: 250px;

    .mat-icon-button {
      height: 64px;
      width: 64px;
      outline: 0;
    }

    h1 {
      font-size: 15px;
      text-transform: none;
      font-weight: bold;
      color: $background;
    }
  }

  .header-right {
    display: flex;
    align-items: flex-end; //consider using flex-end - warning
    padding: 8px 16px;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    background-color: white;
  }
}

//shadow-box
.shadow-box {
  box-shadow: 0 0px 3px black;
  width: 100%;
  height: 1px;
  background-color: #00000033;
  margin-bottom: 15px;
}

//stepper
.mat-step-header {
  .mat-step-icon {
    // border-color: $stepper-color;
    border-width: 3px;
    border-style: solid;
    width: 38px;
    height: 38px;
    background-color: inherit;
    // color: $stepper-color;

    &.mat-step-icon-selected {
      color: $white;
      //   background-color: $stepper-color;
    }

    &.mat-step-icon-state-edit {
      //   background-color: $stepper-color !important;
      color: $white;
    }

    &.mat-step-icon-state-done {
      //   background-color: $stepper-color !important;
      color: $white;
    }
  }
}

//card
.mat-card-title {
  text-align: center;
  font-size: 30px;
  //   color: $font-color;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mat-card {
  .mat-card-actions {
    display: flex;
    justify-content: center;

    .mat-button {
      width: 196px;
    }
  }

  &.card-summary {
    .mat-card-title {
      font-size: 25px;
    }

    .mat-list {
      .mat-list-item {
        h3 {
          font-weight: 600;
          font-size: 15px;
          //   color: $font-color;
          margin-bottom: 0;
        }
        span {
          //   color: $font-color-transparent;
          padding-left: 15px;
          font-weight: 400;
        }
      }
    }
  }
}

.flex-spacer {
  margin-left: auto;
}

td.mat-cell  {
  padding-left: 20px  !important;
}

th.mat-header-cell  {
  padding: 0px 20px !important;
}
