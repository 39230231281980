@use '@angular/material' as mat;
@import '@angular/material/theming';
// @import '~custom';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$app-indigo: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #43425d,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$primary-palette: mat.define-palette($app-indigo, 600);
$accent-palette: mat.define-palette(mat.$yellow-palette, 600, 300, 600);
$warn-palette: mat.define-palette(mat.$red-palette);
$theme: mat.define-light-theme($primary-palette, $accent-palette, $warn-palette);
@include mat.all-legacy-component-themes($theme);

$primary: mat.get-color-from-palette($primary-palette);
$secondary: #a3a0fb;
$accent: mat.get-color-from-palette($accent-palette);
$warn: mat.get-color-from-palette($warn-palette);
$background: #fafafa;

$mini-size: 8px;
$small-size: 10px;
