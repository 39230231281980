@import '@angular/cdk/overlay-prebuilt.css';
@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import './styles/variables';
@import './styles/globals';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
    font-family: 'Poppins', 'Times New Roman', Times, serif;

}
@import '~alertifyjs/build/css/alertify.min.css';
@import "~alertifyjs/build/css/themes/bootstrap.min.css";
.navigation-item-label{
    color: rgb(96, 90, 90);
}
.mat-card{
    border-radius: 20px !important;
}
 button .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
    color: #000000de !important;
    background-color: #fff;
    border: 2px solid #a3a0fb;

}
mat-sidenav{
  background-color: #fff !important;
}
.ajs-message, .ajs-dialog {
  font-size: 1em;
}
